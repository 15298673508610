import last from 'lodash/last';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import axios from 'axios';
import { dataTransformer, productsToVariantsTransformer } from './dataTransformer';
import { makeShopifyClient } from './productResolvers';

const PER_PAGE = 20;
const PRODUCT_TYPE_API = "https://inkbox.com/api/product-types/searchable"

class Pagination {
  freshSearch = true;

  products = [];

  variants = [];

  prevSearch = '';

  productTypes = [];

  constructor(sdk) {
    this.sdk = sdk;
  }

  async init() {
    console.log("init")
    await this._fetchSearchableProductTypes();
    this.shopifyClient = await makeShopifyClient(this.sdk);
  }

  /**
   * This method is used to fetch the next page of variants.
   *
   * @param search
   * @returns {Promise<{pagination: {hasNextPage: boolean}, products: unknown[]}|*>}
   */
  async fetchNext(search) {
    console.log("fetchNext", search)

    const searchHasChanged = search !== this.prevSearch;

    console.log("searchHasChanged", searchHasChanged)

    if (searchHasChanged) {
      this.prevSearch = search;
      this._resetPagination();
    }

    // If there is a satisfactory size of variants to fill the next
    // page there is no need to fetch any more products and extract their variants
    // until the next click on the "Load more" button
    const nothingLeftToFetch =
      (!!this.products.length && !last(this.products).hasNextPage) ||
      (!this.freshSearch && !this.products.length);

    console.log("nothingLeftToFetch", nothingLeftToFetch)

    const hasEnoughVariantsToConsume = this.variants.length >= PER_PAGE || nothingLeftToFetch;

    console.log("hasEnoughVariantsToConsume", hasEnoughVariantsToConsume)

    if (hasEnoughVariantsToConsume) {
      const variants = this.variants.splice(0, PER_PAGE);
      const lastProduct = this.products.find(product => product.id === last(variants).productId);
      return {
        pagination: {
          // There is going to be a next page in the following two complimentary cases:
          // A). The product corresponding to the last variant belongs is tagged as having a next page
          // B). There are variants left to consume in the in-memory variants list
          hasNextPage: get(lastProduct, ['hasNextPage'], false) || this.variants.length > 0
        },
        products: variants.map(dataTransformer)
      };
    }

    // When there are not enough variants to fill the page, we need to fetch more products,
    // extract their variants and then call this method recursively to render the next page.
    await this._fetchMoreProducts(search);
    return this.fetchNext(search);
  }


  /**
   * This method will either fetch the first batch of products or the next page
   * in the pagination based on the user search and depending on whether the user
   * has already requested an initial batch of products or not
   *
   * @param search
   * @returns {Promise<void>}
   * @private
   */
  async _fetchMoreProducts(search) {
    console.log("_fetchMoreProducts")

    const noProductsFetchedYet = this.products.length === 0;

    console.log("noProductsFetchedYet", noProductsFetchedYet)

    const nextProducts = noProductsFetchedYet
      ? await this._fetchProducts(search)
      : await this._fetchNextPage(this.products);

    console.log("nextProducts", nextProducts)

    const nextVariants = productsToVariantsTransformer(nextProducts);

    console.log("nextVariants", nextVariants)

    this.products = uniqBy([...this.products, ...nextProducts], 'id');

    console.log("this.products", this.products)

    this.variants = sortBy(uniqBy([...this.variants, ...nextVariants], 'id'), ['title', 'sku']);

    console.log("this.variants", this.variants)

    this.freshSearch = false;
  }

  /**
   * This method is used to fetch the first batch of products
   * based on the user search.
   *
   * @param search
   * @returns {Promise<*>}
   * @private
   */
  async _fetchProducts(search) {
    console.log("_fetchProducts")

    const query = {
      query: `(product_type:"${this.productTypes.join('" OR product_type:"')}") AND (variants:['sku:${search}'] OR title:${search})`
    };

    console.log(query)

    return await this.shopifyClient.product.fetchQuery({
      first: PER_PAGE,
      sortBy: 'TITLE',
      reverse: true,
      ...(search.length && query)
    });
  }

  /**
   * This method is used to fetch the product types that are searchable
   *
   * @returns {Promise<axios.AxiosResponse<any>>}
   * @private
   */
  async _fetchSearchableProductTypes() {
    console.log("Getting searchable")

    return await axios.get(PRODUCT_TYPE_API).then((response) => {
      this.productTypes = response.data
      console.log("got searchable, adding to this", this.productTypes)
    })
  }


  /**
   * This method is used to fetch the next page of products
   * based on the user search.
   *
   * @param products
   * @returns {Promise<*>}
   * @private
   */
  async _fetchNextPage(products) {

    console.log("_fetchNextPage", products)

    return (await this.shopifyClient.fetchNextPage(products)).model;
  }

  _resetPagination() {
    console.log("_resetPagination")

    this.products = [];
    this.variants = [];
    this.freshSearch = true;
  }
}

/**
 * This method is used to create a pagination instance.
 *
 * @param sdk
 * @returns {Promise<Pagination>}
 */
const makePagination = async sdk => {

  console.log("makePagination")

  const pagination = new Pagination(sdk);
  await pagination.init();
  return pagination;
};

export default makePagination;
