import { setup, renderSkuPicker } from 'shared-sku-app';
import { fetchProductPreviews, makeProductSearchResolver } from './productResolvers';

import logo from './InkboxLogo.png';

const DIALOG_ID = 'dialog-root';

/**
 * This method is used to make the CTA for the field.
 *
 * @param fieldType
 * @returns {string}
 */
function makeCTA(fieldType) {
  return fieldType === 'Array' ? 'Select products' : 'Select a product';
}

/**
 * This method is used to validate the parameters passed to the app.
 *
 * @param parameters
 * @returns {null|string}
 */
export function validateParameters(parameters) {
  if (parameters.storefrontAccessToken.length < 1) {
    return 'Provide the storefront access token to your Shopify store.';
  }

  if (parameters.apiEndpoint.length < 1) {
    return 'Provide the Shopify API endpoint.';
  }

  return null;
}

/**
 * This method is used to render the dialog.
 * It is called every time the user clicks on the field.
 * It is also called when the user clicks on the field and the dialog is already open.
 * In this case, the dialog is not re-rendered, but the parameters are updated.
 * This is useful when the user changes the parameters in the field settings.
 * The dialog is re-rendered with the new parameters.
 * The parameters are passed to the dialog via the `sdk.parameters.invocation` object.
 * The `sdk.parameters.installation` object contains the installation parameters.
 *
 * @see https://www.contentful.com/developers/docs/extensibility/ui-extensions/sdk-reference/#parameters
 * @param sdk
 * @returns {Promise<void>}
 */
async function renderDialog(sdk) {
  const container = document.createElement('div');
  container.id = DIALOG_ID;
  container.style.display = 'flex';
  container.style.flexDirection = 'column';
  document.body.appendChild(container);

  renderSkuPicker(DIALOG_ID, {
    sdk,
    fetchProductPreviews,
    fetchProducts: await makeProductSearchResolver(sdk)
  });

  sdk.window.startAutoResizer();
}

/**
 * This method is used to open the dialog.
 * It is called every time the user clicks on the field.
 *
 * @param sdk
 * @param currentValue
 * @param config
 * @returns {Promise<*|*[]>}
 */
async function openDialog(sdk, currentValue, config) {
  const skus = await sdk.dialogs.openCurrentApp({
    allowHeightOverflow: true,
    position: 'center',
    title: makeCTA(sdk.field.type),
    shouldCloseOnOverlayClick: true,
    shouldCloseOnEscapePress: true,
    parameters: config,
    width: 1400
  });

  return Array.isArray(skus) ? skus : [];
}

/**
 * This method is used to determine whether the field should be disabled or not.
 * It is called every time the field value changes.
 *
 * @returns {boolean}
 */
function isDisabled(/* currentValue, config */) {
  // No restrictions need to be imposed as to when the field is disabled from the app's side
  return false;
}

/**
 * This is the entry point of the app.
 */
setup({
  makeCTA,
  name: 'Shopify V1',
  logo,
  description:
    'The Shopify app allows editors to select products from their Shopify account and reference them inside of Contentful entries.',
  color: '#ffe700',
  parameterDefinitions: [
    {
      id: 'storefrontAccessToken',
      name: 'Storefront Access Token',
      description: 'The storefront access token to the Inkbox Shopify store',
      type: 'Symbol',
      required: true
    },
    {
      id: 'apiEndpoint',
      name: 'API Endpoint',
      description: 'Inkbox Shopify API endpoint',
      type: 'Symbol',
      required: true
    }
  ],
  fetchProductPreviews,
  renderDialog,
  openDialog,
  isDisabled,
  validateParameters
});
