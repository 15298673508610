import identity from 'lodash/identity';
import difference from 'lodash/difference';
import get from 'lodash/get';
import Client from 'shopify-buy';
import makePagination from './Pagination';

import {validateParameters} from '.';
import {previewsToVariants} from './dataTransformer';

/**
 * Creates a Shopify client based on the installation parameters
 * provided by the user.
 * @see https://shopify.github.io/js-buy-sdk/
 * @see https://shopify.dev/docs/storefront-api/reference
 * @param installation
 * @returns {Promise<*>}
 */
export async function makeShopifyClient({ parameters: { installation } }) {
  const validationError = validateParameters(installation);

  if (validationError) {
    throw new Error(validationError);
  }

  const { storefrontAccessToken, apiEndpoint } = installation;

  return Client.buildClient({
    domain: apiEndpoint,
    storefrontAccessToken
  });
}


/**
 * Fetches the products searched by the user
 *
 * Note: currently there is no way to cover the edge case where the user
 *       would have more than 250 products selected. In such a case their
 *       selection would be cut off after product no. 250.
 *
 * @param skus
 * @param config
 * @returns {Promise<*[]>}
 */
export const fetchProductPreviews = async (skus, config) => {

  console.log("fetchProductPreviews", skus)

  if (!skus.length) {
    return [];
  }

  // if the sku contains the gid:// prefix, it means that it's already decoded, otherwise decode it
  const validIds = skus.map(sku => sku.startsWith('gid://') ? sku : atob(sku));

  console.log("validIds", validIds)

  const queryIds = validIds.map(sku => `"${sku}"`).join(',');

  console.log("queryIds", queryIds)

  const query = `
  {
    nodes (ids: [${queryIds}]) {
      id,
      ...on ProductVariant {
        sku,
        image {
          src: originalSrc
        },
        title,
        product {
          id,
          title
        }
      }
    }
  }
  `;

  console.log("query", query)

  const { apiEndpoint, storefrontAccessToken } = config;

  const res = await window.fetch(`https://${apiEndpoint}/api/2019-10/graphql`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-shopify-storefront-access-token': storefrontAccessToken
    },
    body: JSON.stringify({ query })
  });

  const data = await res.json();

  console.log("data", data)

  const nodes = get(data, ['data', 'nodes'], []).filter(identity);

  console.log("nodes", nodes)

  const variantPreviews = nodes.map(previewsToVariants(config));

  console.log("variantPreviews", variantPreviews)

  // missingVariants are variants that were selected by the user but are not available
    // in the store anymore. We still want to display them in the app so that the user
    // can remove them from the selection.

  const missingVariants = difference(
      validIds,
      variantPreviews.map(variant => variant.id)
  ).map(sku => ({ sku, isMissing: true, name: '', image: '' }));

  console.log("missingVariants", missingVariants)

  return [...variantPreviews, ...missingVariants];
};

/**
 * Creates a resolver that fetches the next page of products
 * based on the search query provided by the user.
 *
 * Shopify does not support indexed pagination, only infinite scrolling
 * @see https://community.shopify.com/c/Shopify-APIs-SDKs/How-to-display-more-than-20-products-in-my-app-when-products-are/td-p/464090 for more details (KarlOffenberger's answer)
 *
 * @param sdk
 * @returns {Promise<function(*): Promise<{pagination: {hasNextPage}, products: *}|*>>}
 */
export const makeProductSearchResolver = async sdk => {
  const pagination = await makePagination(sdk);
  return search => pagination.fetchNext(search);
};
