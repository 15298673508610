import get from 'lodash/get';
import last from 'lodash/last';
import flatten from 'lodash/flatten';

/**
 * Transforms the API response of Shopify into
 * the product schema expected by the SkuPicker component
 *
 * @param product
 * @returns {{image, name, id, sku}}
 */
export const dataTransformer = product => {
    console.log("dataTransformer", product)

    const image = get(product, ['image', 'src'], '');
    const sku = get(product, ['sku'], '');

    console.log("product", product)
    console.log("image", image)
    console.log("sku", sku)

    return {
        id: product.id,
        image,
        name: product.title,
        sku
    };
};

/**
 * Transforms the API response of Shopify into
 * the product schema expected by the SkuPicker component
 *
 * @param products
 */
export const productsToVariantsTransformer = products =>
  flatten(
    products.map(product => {
      const variants = product.variants.map(variant => ({
        ...variant,
        variantSKU: variant.sku,
        sku: variant.id,
        productId: product.id,
        title: product.title,
        hasNextPage: false
      }));
      variants[variants.length - 1].hasNextPage = product.hasNextPage;
      return variants;
    })
  );

/**
 * Transforms the product previews into the variants schema
 * expected by the SkuPicker component
 *
 * @param apiEndpoint
 * @returns {function({sku: *, id: *, image: *, product: *}): {image: *, productId: *, name: *, id: *, sku: string}}
 */
export const previewsToVariants = ({ apiEndpoint }) => ({ sku, id, image, product }) => {
    console.log("previewsToVariants")
    console.log("sku", sku) // shopify sku
    console.log("id", id) // shopify variant id
    console.log("image", image) // shopify image
    console.log("product", product) // shopify product {id, title}

    // Slice the last part of the id which is the variant ID
    const variantId = id.split('/').slice(-1)[0];

    // Slice the last part of the product.id which is the product ID
    const productId = product.id.split('/').slice(-1)[0];

    // Return the variant schema expected by the SkuPicker component
    return {
        id: id,
        image: get(image, ['src'], ''),
        // TODO: Remove sku:id when shared-sku-app supports internal IDs
        // as an alternative piece of info to persist instead of the SKU.
        // For now this is a temporary hack.
        sku: sku,
        productId: product.id,
        name: product.title,
        ...(apiEndpoint &&
          productId && {
            externalLink: `https://${apiEndpoint}${
              last(apiEndpoint) === '/' ? '' : '/'
            }admin/products/${productId}`
          })
    };
};
